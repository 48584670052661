import React from "react";
import { CanCreate, CanUpdate } from "./Permissions";
import AppButton from "./AppButton";

export default function AppHeader(props) {
  const create = CanCreate(props.name, props.context);
  const edit = CanUpdate(props.name, props.context);
  const AddNew = props.addNew;
  const EditItem = props.editItem;
  const unsavedItems = props.unsavedItems;

  return (
    <>
    <div
      className="page-header-item"
      style={{ background: props.theme.top_menu.background }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="header-item-title"
              style={{ color: props.theme.top_menu.font }}
            >
              <div
                className="back-btn"
                style={{ width: 60 }}
                onClick={() => unsavedItems ? props.showUnsavedWarningPromt() : window.history.back()}
              >
                <AppButton btnIcon="keyboard_backspace_icon" />
              </div>
              <span className="header-title-mob">{props.name} </span>
              {props.subpage && (
                <span
                  style={{
                    width: 40,
                    fontSize: 30,
                    position: "relative",
                    top: 7,
                  }}
                  className="material-icons"
                >
                  <span className="header-title-mob">arrow_right_alt_icon</span>
                </span>
              )}

              <span className="header-title-mob">{props.subpage}</span>
              {props.subpage2 && (
                <span
                  style={{
                    width: 40,
                    fontSize: 30,
                    position: "relative",
                    top: 7,
                  }}
                  className="material-icons"
                >
                  <span className="header-title-mob">arrow_right_alt_icon</span>
                </span>
              )}
              <span className="header-title-mob"> {props.subpage2}</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="action-buttons" style={{ position: "fixed" }}>
              {create && props.addNew && <AddNew />}

              {edit && props.editItem && (
                <>
                  <EditItem />
                </>
              )}

              {props.saveBtn && (
                <a onClick={() => {props.saveBtn()}}>
                  <AppButton
                    text="Save"
                    btnIcon="save"
                    stylename="primary"
                    style={{
                      background: props.theme.top_menu.button_background,
                      color: props.theme.top_menu.button_font,
                    }}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    {
      props.spacer && (<p style={{ marginTop: "80px" }} />)
    }
    
    </>
  );
}
