import config from "../config";

const getRequisitions = async () => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/requisitions`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getRequisitionSingle = async (id) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/requisitions/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateRequisition = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createRequisition = async (obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteProduct = async (id) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/requisitions/lineDelete/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const approveRequisition = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions/approved/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const rejectRequisition = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions/rejected/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const requestRequisitionApproval = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions/requestApproval/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const duplicateRequisition = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/requisitions/duplicate/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPurchaseOrders = async () => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/purchaseOrders`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const downloadDocument = async (id) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/documents/url/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createDelivery = async (obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deliveries`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateDelivery = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deliveries/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteDelivery = async (id, obj) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deliveries/delete/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPurchaseOrderProducts = async (id) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/products/purchaseOrderIndex/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

//Notifications

const saveNotifications = async (arr) => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: arr,
    endpoint: `/api/notifications`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getNotifications = async () => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/notifications`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getApprovedCostings = async () => {
  const options = {
    base: "https://purchasing-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/costings/approvedIndex`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  createDelivery,
  getPurchaseOrderProducts,
  deleteDelivery,
  updateDelivery,
  getRequisitionSingle,
  rejectRequisition,
  requestRequisitionApproval,
  getRequisitions,
  updateRequisition,
  approveRequisition,
  duplicateRequisition,
  createRequisition,
  deleteProduct,
  getPurchaseOrders,
  downloadDocument,
  saveNotifications,
  getNotifications,
  getApprovedCostings
};
