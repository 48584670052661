/**
 * Acts as a wrapper for managing local and session storage
 * 
 * Constructor accepts two parameters
 *  type - integer - optional - default = 0 - ** Determines if manager should use local 
 *                                               or session storage. 0 = local, 1 = session
 * Constructor will throw an exception if you pass an invalid type. 
 */
class StorageManager {
    constructor(type = 0) {
        switch(type) {
            case 0: 
                this.type = type; 
                this.typeString = "Local"; 
                this.storage = window.localStorage;
                break;
            case 1: 
                this.type = type; 
                this.typeString = "Session";
                this.storage = window.sessionStorage;
                break;
            default: throw `${type} | Invalid Type Passed - Supported: 
                0 = Local, 1 = Session`;
        }
    }

    /**
     * returns ?bool - true = consent, false = rejected, null = no decision
     * (null is falsy in JS so don't need to worry about it when doing checks. More for feedback handling if you wish to process it)
     * If the parse fails then grant shall be cleared and in this instance false returned
     */
    checkGrant() {
        const grant = window.localStorage.getItem("grant");
        if(grant == null) return null;
        try {
            return (grant != undefined && parseInt(grant))
        } catch (error) {
            console.error(`StorageManager: Failed to check parse grant: ${error}`);
            window.localStorage.removeItem("grant");
            return false;
        }
    }

    /**
     * Will fetch item from storage. If the grant has not been gained then null will be returned
     * If item does not exist then null will also be returned
     * Parameters:
     *  name - string - required | item name in storage
     *  fallback - mixed - optional - default undefined | if item not found return this
     * returns ?string
     */
    getItem(name, fallback = undefined) {
        if(!this.checkGrant()) return null;
        const item = this.storage.getItem(name);

        if(item == null && fallback !== undefined) return fallback;
        return item;
    }

    /**
     * Set an item into storage. If grant has not been accepted then null will be returned
     * 
     * Parameters:
     *  name - string - required | item name for storage
     *  value - mixed - required | value to store in storage (not objects)
     * If you want to store objects or arrays then use the storeObject call
     * returns ?bool - true = success, false = failed, null = no grant/incorrect usage
     */
    setItem(name, value) {
        if(!this.checkGrant()) return null;
        try {
            if(typeof value == "object") {
                console.error("StorageManager: setItem cannot process objects. Use setObject instead()");
                return null;
            }
            this.storage.setItem(name, value);
        } catch (error) {
            console.error(`StorageManager: Failed to store item: ${error}`)
            return false;
        }
        return true;
    }

    /**
     * Fetch an object from storage and parse it from a string to object
     * 
     * Parameters:
     *  name - string - required | item name for storage
     *  fallback - object - optional - default = {} | If object is not found return this
     * 
     * return:
     *  ?object - object pulled from storage. Null if failed parse or no grant
     */
    getObject(name, fallback = {}) {
        if(!this.checkGrant()) return null;
        let item = this.storage.getItem(name);
        if(item == null && fallback != {}) return fallback

        try {
            item = JSON.parse(item);
        } catch (error) {
            console.error(`StorageManager: Failed to parse object from storage: ${error}`);
            return null;
        }
        return item;
    }

    /**
     * Stringify an object and store it in storage
     * 
     * Parameters:
     *  name - string - required | item name for storage
     *  obj - object - required | object to be parsed and put into storage
     * 
     * return:
     *  ?bool - true = successfully stored, false = error, null = No grant
     */
    setObject(name, obj) {
        if(!this.checkGrant()) return null;
        try {
            let item = JSON.stringify(obj);
            this.storage.setItem(name, item);
        } catch (error) {
            console.error(`StorageManager: Failed to store object: ${error}`);
            return false;
        }

        return true;
    }

    removeItem(name) {
        if(!this.checkGrant()) return null;

        try {
            this.storage.removeItem(name);
        } catch (error) {
            console.error(`StorageManager: Failed to remove item: ${error}`);
            return false;
        }

        return true;
    }
}

export default StorageManager