import config from "../config";

const getSalesOpps = async () => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/salesOpps`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSalesOppSingle = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/salesOpps/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSalesOppsByOpen = async () => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: {},
    endpoint: `/api/salesOpps/indexByOpen`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveGenerateForecast = async (obj) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: { data: obj },
    endpoint: `/api/forecast/generateForecast`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getForecastShow = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/forecast/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveSalesOpp = async (obj) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/salesOpps`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateSalesOpp = async (obj) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/salesOpps/${obj.id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteSalesOpp = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/salesOpps/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

// Build Solution

const getBuildSolution = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/buildSolutions/showForSalesOpp/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateBuildSolution = async (obj, id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/buildSolutions/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getScotsman = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/scotsmanReviews/showForSalesOpp/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateScotsman = async (obj, id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/scotsmanReviews/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getProposal = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/proposals/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getProposalForSalesOpp = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/proposals/showForSalesOpp/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateProposal = async (obj, id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/proposals/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteProposalCosting = async (id) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/proposals/costings/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSalesStats = async (obj) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/salesOpps/statistics`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getForecastByFilter = async (obj) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/salesOpps/indexByFilter`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getSalesWon = async (id, dateFrom, dateTo, lost = false) => {
  const options = {
    base: "https://sales-opp-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/salesOpps/indexByWon?agent_id=${id}&date_from=${dateFrom}&date_to=${dateTo}${lost ? "&lost=true" : ""}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getSalesOpps,
  getSalesOppSingle,
  saveSalesOpp,
  updateSalesOpp,
  deleteSalesOpp,
  getBuildSolution,
  updateBuildSolution,
  getScotsman,
  updateScotsman,
  getProposal,
  getProposalForSalesOpp,
  updateProposal,
  deleteProposalCosting,
  getSalesOppsByOpen,
  saveGenerateForecast,
  getForecastShow,
  getSalesStats,
  getForecastByFilter,
  getSalesWon,
};
