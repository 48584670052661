

import config from "../config"

const getMailer = async () => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/mailerSettings`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const storeMailer = async (obj) => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/settings/saveMailerSettings`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

export { getMailer, storeMailer };
