import { Snackbar, Typography, Button, Slide } from "@mui/material";
import Fade from "@mui/material/Fade"
import React, {useEffect, useState} from "react";

export default function CookieRequest(props) {
    const [open, setOpen] = useState(false);
    const [grant, setGrant] = useState(false);

    const allow = () => {
        window.localStorage.setItem("grant", 1);
        setOpen(false);
    }

    const deny = () => {
        window.localStorage.setItem("grant", 0);
        setOpen(false);
    }

    useEffect(() => {
        if(window.localStorage.getItem("grant") == undefined) {setOpen(true); return};
        setGrant(window.localStorage.getItem("grant"));
    }, []);
    
    return (
        <>
            {
                grant ? (
                    <></>
                ) : (
                    <Snackbar 
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    TransitionComponent={Fade}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    message={(
                        <div style={{ textAlign: "center" }}>
                            <Typography>MYBE Suite uses Cookies and Local Storage to enhance your experience such as storing settings and information<br/>Do you consent to the MYBE suite of applications storing cookies and application related data on your computer/device?</Typography>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "green", color: "white", marginRight: "10px" }}
                                onClick={() => {allow()}}
                            >
                                Allow
                            </Button>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "red", color: "white" }}
                                onClick={() => {deny()}}
                            >
                                Deny
                            </Button>
                        </div>
                    )}
                />
                )
            }
        </>
    )
}