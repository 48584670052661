import config from "../config";

const moduleSettingsIndex = async (page = 1, perPage = 50) => {
    const options = {
        base: "https://tenant-middleware",
        params: {page: page, perPage: perPage},
        payload: false,
        endpoint: `/api/moduleSettings`,
        method: "GET"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

/**
 * Params: page - int, perPage - int, category_type - string
 */
const moduleSettingsIndexForCategory = async (params = {}) => {
    const options = {
        base: "https://tenant-middleware",
        params: params,
        payload: false,
        endpoint: `/api/moduleSettings/indexByCategory`,
        method: "GET"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const moduleSettingsShow = async (moduleId) => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: false,
        endpoint: `/api/moduleSettings/${moduleId}`,
        method: "GET"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const moduleSettingsStore = async (payload = {}) => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/moduleSettings`,
        method: "POST"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const moduleSettingsUpdate = async (moduleId, payload = {}) => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/moduleSettings/${moduleId}`,
        method: "PUT"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

export {
    moduleSettingsIndex,
    moduleSettingsIndexForCategory,
    moduleSettingsShow,
    moduleSettingsStore,
    moduleSettingsUpdate
}