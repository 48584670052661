
import config from "../config";

const getKnowledgeIndex = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/knowledge`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getKnowledgeRecord = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/knowledge/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const updateKnowledgeRecord = async (id, obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/knowledge/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const createKnowledgeBaseRecord = async (obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/knowledge`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



const searchKnowledge = async (query) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/knowledgeBase/search?subject=${query}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const getFile = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/fileRecord/${id}/download`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


export { getKnowledgeIndex, getFile, getKnowledgeRecord, updateKnowledgeRecord, searchKnowledge, createKnowledgeBaseRecord };
