import config from "../config";

const getFiles = async (obj, path) => {
    const options = {
        base: "https://intranet/index",
        params: path,
        payload: obj,
        endpoint: `/api/files`,
        method: "GET",
        service: true
    }
 
    const data = await fetch(
        `${config.appURL}/api/fileTest`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const deleteFiles = async (filename, path) => {
    const options = {
        base: "https://intranet/index",
        params: filename,
        payload: false,
        path: path,
        endpoint: `/api/files/${filename}`,
        method: "DELETE",
        service: true

    }
 
    const data = await fetch(
        `${config.appURL}/api/deleteFile`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const uploadFiles = async (obj, path) => {
    const options = {
        base: "https://intranet/index",
        params: path,
        payload: obj,
        endpoint: `/api/uploadFiles`,
        method: "POST",
        //service: true
    }
    let fileTest = options.payload.files;
    console.log(fileTest.length);
    console.log(Array.isArray(fileTest))
 
    const data = await fetch(
        `${config.appURL}/api/uploadFile`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            
        }
    );
    console.log( JSON.stringify(options.payload))

    const res = await data.json();

    return res;
};

const downloadFile = async (filename, path) => {
    const options = {
        base: "https://intranet/index",
        params: filename,
        payload: false,
        path: path,
        endpoint: `/api/downloadFile/${filename}`,
        method: "GET",
        service: true

    }
 
    const data = await fetch(
        `${config.appURL}/api/downloadFile`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const folderPath = async (path) => {
    const options = {
        base: "https://intranet/index",
        params: path,
        payload: false,
        endpoint: `/api/downloadFile/${path}`,
        method: "GET",
        service: true

    }
 
    const data = await fetch(
        `${config.appURL}/api/folderPath`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

export { getFiles, deleteFiles, uploadFiles, downloadFile, folderPath };