/**
 * 
 * @description     \ Contacts/Clients express routes
 * @service         \ https://contacts-service
 * @middleware      \ https://clients-micro-middleware
 */
import config from "../config";

/**
 * Clients / Leads / Targets
 */

const clientsWithContactsSelect = async () => {
  const options = {
    base: "https://contacts-service",
    params: false,
    payload: false,
    endpoint: `/api/clients/indexClientContacts`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const clientsAllRelationsSelect = async () => {
  const options = {
    base: "https://contacts-service",
    params: false,
    payload: false,
    endpoint: `/api/clients/indexWithRelations`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getClients = async (prefix) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/${prefix ? prefix : "clients"}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getClientSingle = async (id, prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/${prefix}/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const saveClient = async (obj, prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/${prefix}`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */

const updateClient = async (obj, prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/${prefix}/${obj.ref}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLongLat = async (postcode) => {
  const data = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);

  const res = await data.json();

  return res;
};

const getRestoreIndex = async (prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/${prefix}/restoreIndex`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const clientRestore = async (id, prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/${prefix}/restore/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteClient = async (id, prefix = "clients") => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/${prefix}/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getClientLogs = async (type, id) => {
  var url = {
    1: `/api/logs/customer/${id}`,
    2: `/api/logs/site/${id}`,
    3: `/api/logs/contact/${id}`,
    4: `/api/logs/customerDocument/${id}`,
    5: `/api/logs/siteDocument/${id}`,
  };

  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `${url[type]}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const companiesHouseSearch = async (query) => {
  const options = {
    base: "https://api.company-information.service.gov.uk",
    externalApi: {
      useKey: "companiesHouse",
      auth: "Basic ",
    },
    params: false,
    payload: false,
    endpoint: `/search/companies?q=${query}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const companiesHouseGet = async (id) => {
  const options = {
    base: "https://api.company-information.service.gov.uk",
    externalApi: {
      useKey: "companiesHouse",
      auth: "Basic ",
    },
    params: false,
    payload: false,
    endpoint: `/company/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const companiesHouseGetOfficers = async (id) => {
  const options = {
    base: "https://api.company-information.service.gov.uk",
    externalApi: {
      useKey: "companiesHouse",
      auth: "Basic ",
    },
    params: false,
    payload: false,
    endpoint: `/company/${id}/officers`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargets = async () => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargetSingle = async (id) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargetUserIndex = async () => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/userindex`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargetRestore = async (id) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/restore/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTarget = async (obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const updateTarget = async (obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/${obj.ref}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargetRestoreIndex = async () => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/restoreIndex`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTargetClientRestore = async (id) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/restore/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteTarget = async (id) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getFilterCount = async (name, obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/${name}/filterCount`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const fuzzyFilterNotSearch = async (name, obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/${name}/fuzzyFilterNotSearch`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const fuzzyFilterSearch = async (name, obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/${name}/fuzzyFilterSearch`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetPaginate = async (name, obj) => {
  const newPayLoad = { criteria: obj, page: "1" };

  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: newPayLoad,
    endpoint: `/api/${name}/filterSetPaginate`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetPaginateIndex = async (obj) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetPaginate`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const setPhonePrefFalse = async (id) => {
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/all/setPhonePrefFalse/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCriteriaFuzzySearch = async (endpoint, fieldName, obj) => {
  const newObj = {
    search: obj,
  };
  const options = {
    base: "https://clients-micro-middleware",
    params: false,
    payload: newObj,
    endpoint: `/api/${endpoint}/${fieldName}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
export {
  clientsWithContactsSelect,// direct to the service for selectors (clients + contacts)
  clientsAllRelationsSelect,// direct to the service for selectors (clients + sites + contacts)
  getClients,
  saveClient,
  updateClient,
  deleteClient,
  getClientLogs,
  companiesHouseGet,
  companiesHouseSearch,
  getRestoreIndex,
  clientRestore,
  companiesHouseGetOfficers,
  getClientSingle,
  getLongLat,
  getTargets,
  getTargetSingle,
  getTargetUserIndex,
  getTargetRestore,
  saveTarget,
  updateTarget,
  getTargetRestoreIndex,
  getTargetClientRestore,
  deleteTarget,
  getFilterCount,
  fuzzyFilterSearch,
  fuzzyFilterNotSearch,
  filterSetPaginate,
  setPhonePrefFalse,
  filterSetPaginateIndex,
  getCriteriaFuzzySearch,
};
