import config from "../config";

const DEFAULTPERPAGE = 30;

const templateIndex = async (page, perPage = DEFAULTPERPAGE, params = {}) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage, ...params},
        payload: false,
        endpoint: `/api/form`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateFormIndex = async (templateId, page, perPage = DEFAULTPERPAGE, params = {}) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage, ...params},
        payload: false,
        endpoint: `/api/form/template/${templateId}/indexData`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateVersionIndex = async (templateId, page, perPage = DEFAULTPERPAGE, params = {}) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage, ...params},
        payload: false,
        endpoint: `/api/form/template/${templateId}/versionIndex`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateShow = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/form/template/${templateId}`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formShow = async (templateId, formId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {templateId: templateId},
        payload: false,
        endpoint: `/api/form/form/${formId}`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateStore = async (payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/form/template`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formStore = async (payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/form/form`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateUpdate = async (templateId, payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/form/template/${templateId}`,
        method: "PUT",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formUpdate = async (formId, payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/form/form/${formId}`,
        method: "PUT",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateDestroy = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/form/template/${templateId}`,
        method: "DELETE",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formDestroy = async (templateId, formId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {templateId, templateId},
        payload: false,
        endpoint: `/api/form/form/${formId}`,
        method: "DELETE",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateRestore = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/form/template/${templateId}/restore`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formRestore = async (templateId, formId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {templateId: templateId},
        payload: false,
        endpoint: `/api/form/form/${formId}/restore`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formVerify = async (templateId, payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/form/template/verify/${templateId}`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateActionsIndex = async () => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/actions/template`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formActionsIndex = async () => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/actions/form`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const formLogsIndex = async (formId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {formId: formId},
        payload: false,
        endpoint: `/api/log`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const countersIndex = async (page, perPage = DEFAULTPERPAGE) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage},
        payload: false,
        endpoint: `/api/counter`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const counterShow = async (counterId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/counter/${counterId}`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const counterStore = async (payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/counter`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const counterIncrement = async (counterId) => {
  const options = {
      base: "https://entity-forms-middleware",
      params: false,
      payload: false,
      endpoint: `/api/counter/${counterId}/increment`,
      method: "PUT",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
    res.ok = data.status == 200;
  
    return res;
}

const counterUpdate = async (counterId, payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/counter/${counterId}`,
        method: "PUT",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const counterDestroy = async (counterId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/counter/${counterId}`,
        method: "DELETE",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const counterRestore = async (counterId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/counter/${counterId}/restore`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const generatedPdfsIndex = async (page, perPage = DEFAULTPERPAGE) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage},
        payload: false,
        endpoint: `/api/pdf`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const generatedPdfShow = async (pdfId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/pdf/${pdfId}`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const pdfGenerate = async (payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/pdf`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const generatedPdfDownloadLink = async (pdfId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/pdf/${pdfId}/link`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const triggerAction = async (payload) => {
  const options = {
    base: "https://entity-forms-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/actions/trigger`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const indexPDFsForForm = async (formId, page) => {
  const options = {
    base: "https://entity-forms-middleware",
    params: { page: page },
    payload: false,
    endpoint: `/api/pdf/forForm/${formId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

export {
    templateIndex,
    templateVersionIndex,
    templateShow,
    templateStore,
    templateUpdate,
    templateDestroy,
    templateRestore,

    templateFormIndex,
    formShow,
    formStore,
    formUpdate,
    formDestroy,
    formRestore,
    formVerify,

    templateActionsIndex,
    formActionsIndex,

    formLogsIndex,

    countersIndex,
    counterShow,
    counterStore,
    counterIncrement,
    counterUpdate,
    counterDestroy,
    counterRestore,

    generatedPdfsIndex,
    generatedPdfShow,
    pdfGenerate,
    generatedPdfDownloadLink,

    triggerAction,

    indexPDFsForForm
}