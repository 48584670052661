import config from "../config"
// *
const getClockStatus = async () => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/clock/status`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const getFlexBalance = async () => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/clock/flex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const setClock = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/clock/create`,
        method: "POST"
    }

    let data =  null;

    try {
        data = await fetch(
            `${config.appURL}/api/proxy`, {
                credentials: "include",
                method: 'POST',
                body: JSON.stringify(options),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
    } catch (e) {
        console.error("Clock fetch failed", e);
        return;
    }

    if(data == null) return {success: false, status: 500, data: false};
    
    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const setClockStatus = async (id, OBJ) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: OBJ,
        endpoint: `/api/clock/create`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const getClockTracking = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/clock/tracking/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const getUserSetup = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/user/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const postUserSetup = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/userSetup`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;
    res.statusCode = data.status;

    return res;
}

const updateUserSetup = async (id, obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/userSetup/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

// *
const getHolidayWidgets = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/holiday/widget`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =====================
 *      TIMESHEETS
 * =====================
 */
// *
const getTimesheets = async (id = false, admin = 0) => {
    const options = {
        base: "https://attendance-middleware",
        params: {
            "admin": admin
        },
        payload: false,
        endpoint: id ? `/api/timesheet/${id}` : `/api/timesheet`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const getTimesheetDayTypes = async () => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/timesheetDay/getDayTypes`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const getTimesheetsSummary = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/timesheet/summary/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const confirmTimesheet = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/timesheet/confirm/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const managerConfirmTimesheet = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: {
            'manager': 1
        },
        payload: false,
        endpoint: `/api/timesheet/confirm/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const unconfirmTimesheet = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/timesheet/unconfirm/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};
// *
const updateTimesheetDay = async (obj, id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/timesheet/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
};

/**
 * =====================
 *      SICKNESS
 * =====================
 */
// *
const getSicknessIndexForAdmin = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: {"admin": true},
        payload: false,
        endpoint: `/api/sick`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const getSickness = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: id ? `/api/sick/${id}` : `/api/sick`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const saveSickness = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/sick`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}
// *
const deleteSickness = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/sick/${id}`,
        method: "DELETE"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =====================
 *   COMPANY SETTINGS
 * =====================
 */

const getSettings = async () => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/settings`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const storeSettings = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/settings`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const updateSettings = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/settings`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const getCompliance = async (date) => {
    const options = {
        base: "https://attendance-middleware",
        params: { "date": date },
        payload: false,
        endpoint: `/api/compliance`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

/**
 * =====================
 *   SHIFT PATTERNS
 * =====================
 */

const getPattern = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: id ? `/api/shiftPattern/${id}` : `/api/shiftPattern`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const savePattern = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/shiftPattern`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const updatePattern = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/shiftPattern/${obj.id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const deletePattern = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/shiftPattern/${id}`,
        method: "DELETE"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

/**
 * =====================
 *   COMPANY CLOSURES
 * =====================
 */

const getCompanyClosure = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/closure`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const saveCompanyClosure = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/closure`,
        method: "post"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const updateCompanyClosure = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/closure/${obj.id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const deleteCompanyClosure = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/closure/${id}`,
        method: "DELETE"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

/**
 * =====================
 *   RETURN TO WORK
 * =====================
 */

const getReturnWorkForm = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/returnWork/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const updateReturnWorkForm = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/returnWork`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

/**
 * =====================
 *   HOLIDAY REQUESTS
 * =====================
 */

const approveHolidayRequest = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/holiday/${id}/approve`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const denyHolidayRequest = async (id) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: `/api/holiday/${id}/deny`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const getHolidayRequest = async (id = false) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: false,
        endpoint: id ? `/api/holiday/${id}` : `/api/holiday`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const saveHolidayRequest = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: "/api/holiday",
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

const sendEmail = async (obj) => {
    const options = {
        base: "https://attendance-middleware",
        params: false,
        payload: obj,
        endpoint: "/api/email/send",
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )
    const res = await data.json();
    res.success = data.ok;
    
    return res;
}

export {
    getClockStatus,
    getFlexBalance,
    setClock,
    setClockStatus,
    getClockTracking,

    getUserSetup,
    postUserSetup,
    updateUserSetup,

    getHolidayWidgets,

    getTimesheets,
    getTimesheetDayTypes,
    updateTimesheetDay,
    getTimesheetsSummary,
    confirmTimesheet,
    managerConfirmTimesheet,
    unconfirmTimesheet,

    getSickness,
    getSicknessIndexForAdmin,
    saveSickness,
    deleteSickness,

    getSettings,
    storeSettings,
    updateSettings,

    getCompliance,

    getPattern,
    savePattern,
    updatePattern,
    deletePattern,

    getCompanyClosure,
    saveCompanyClosure,
    updateCompanyClosure,
    deleteCompanyClosure,

    getReturnWorkForm,
    updateReturnWorkForm,

    getHolidayRequest,
    approveHolidayRequest,
    denyHolidayRequest,
    saveHolidayRequest,
    sendEmail
}