import config from "../config";

const getMail = async (page) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/email/getInbox/${page}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
};

const indexMailers = async () => {
  const options = {
    base: "https://email-service",
    params: false,
    payload: false,
    endpoint: `/api/mailer`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const sendMail = async (obj) => {
  const options = {
    base: "https://email-service",
    params: false,
    payload: obj,
    endpoint: `/api/email/send`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const getUserMailer = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/getUserMailer`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveUserMailer = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/saveMailer`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateUserMailer = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/settings/storeUserMailer`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export { indexMailers, getMail, sendMail, getUserMailer, saveUserMailer, updateUserMailer };
