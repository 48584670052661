/**
 * Will handle the generation of Title Change Events in the Page Header Title
 * 
 * Construct object then call the update title function
 * This will implement an auto wait to ensure that the header is fully loaded and the listener attached before attempting to update the title
 */
import React from "react";
import { CircularProgress, Typography } from "@mui/material";

const STANDARDWAIT = 2000;
const SHORTWAIT = 500;

class TitleControl {
    constructor(title = "") {
        try {
            this.title = title;
            this.timeout = null;
        } catch (error) {
            this.title = false;
            console.error("Failed to initlise TitleControl", e);
        }
    }

    // Message - string (What is displayed next to load bar), Value - Integer (Perecentage on load bar - leave null for indeterminate loader)
    setLoading(message = false, value = false) {
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {this.dispatchLoading(value, message)}, SHORTWAIT);
        return this;
    }

    dispatchLoading(value, message) {
        window.dispatchEvent(new CustomEvent(
            "pageTitleChange", {detail: 
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "1vw" }}>
                    <CircularProgress 
                        style={{ height: "20px", width: "20px", color: "white" }}
                        variant={value ? "determinate" : "indeterminate"}
                        value={value ? value : 0}
                    />
                    <Typography variant="body1">{message}</Typography>
                </div>
            })
        )
        this.timeout = null;
    }

    // title - string (Message to be displayed on the title bar as a standard stirng)
    setTitle(t = this.title) {
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {this.dispatchTitle(t)}, STANDARDWAIT);
        return this;
    }

    dispatchTitle(title) {
        window.dispatchEvent(new CustomEvent(
            "pageTitleChange", {detail: 
                <Typography variant="body1">{title}</Typography>
            }
        ));
        this.timeout = null;
    }

    // Control - JSX Component (The Component you want displayed in the title bar), t - string (The title string you want displayed)
    setControlTitle(control, t = this.title) {
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {this.dispatchControl(control, t)}, SHORTWAIT)
        return this;
    }

    dispatchControl(control, t) {
        window.dispatchEvent(new CustomEvent(
            "pageTitleChange", {detail:
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", gap: "1vw" }}>
                    {control}
                    <Typography variant="body1">{t}</Typography>
                </div>
            }
        ));
        this.timeout = null;
    }
}

export default TitleControl