import config from "../config";

const getFormsIndex = async (type) => {
  const options = {
    base: "https://forms-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/forms/typeIndex/${type}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getForm = async (id) => {
  const options = {
    base: "https://forms-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/forms/${id}`,
    method: "GET",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createForm = async (obj) => {
  const options = {
    base: "https://forms-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/forms`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateForm = async (id, obj) => {
  const options = {
    base: "https://forms-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/forms/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteForm = async (id) => {
  const options = {
    base: "https://forms-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/forms/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export { getFormsIndex, getForm, updateForm, createForm, deleteForm };
