import config from "../config";
const getDynamicListIndex = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/dynamicList`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDynamicListSingle = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/dynamicList/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveDynamicList = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/dynamicList`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateDynamicList = async (obj, id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/dynamicList/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteDynamicList = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/dynamicList/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreDynamicList = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/dynamicList/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetPaginate = async (id, type) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/dynamicList/filterSetPaginate/${id}?page=1&list_type=${type}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getDynamicListIndex,
  getDynamicListSingle,
  saveDynamicList,
  updateDynamicList,
  deleteDynamicList,
  restoreDynamicList,
  filterSetPaginate,
};
