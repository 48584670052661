import React from "react";

import { Paper, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Props
 * 
 * context - pass in the context of the parent component
 * back - boolean - whether to display the back button
 * name - string - title of the page to display
 * variant - enum string (basic | linear) - display solid background or lienar background. Colors defined by context theme.
 */
export default function SimplifiedHeader(props) {
    return (
    <Paper 
        style={{
            backgroundColor: props.variant == "basic" || props.variant == undefined 
                ? props.context.theme.top_menu.background 
                : null,
            backgroundImage: props.variant == "linear" 
                ? `linear-gradient(145deg, ${props.context.theme.sidebar.background}, ${props.context.theme.top_menu.button_background})` 
                : null,
            color: props.context.theme.top_menu.font,
            padding: "0.5%",
            marginBottom: "2.5vh",
            marginLeft: "-1vw",
            width: "84.2vw",
        }} 
        elevation={12}
    >
        <span style={{ display: "flex", alignItems: "center" }}>
            {
                props.back ? (
                    <IconButton
                        size={"large"}
                        style={{
                            color: props.context.theme.top_menu.font
                        }}
                        onClick={() => {window.history.go(-1)}}
                    >
                        <ArrowBackIcon fontSize="inherit"/>
                    </IconButton>
                ) : (<p></p>)
            }
            <h4 style={{ fontFamily: "sans-serif" }}>{props.name}</h4>
        </span>
    </Paper>
    );
}