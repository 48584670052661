import React from "react";
import { Watch } from "react-loader-spinner";
import "./APILoaderStyles.css";

export default function ApiLoader(props) {
  if (props.status) {
    return (
      <div className="loaderBackground">
        <div className="loaderContainer">
          <Watch
            color="black"
            height={100}
            width={100}
          />
        </div>
        <div className="loaderText">
          <b>{props.data}</b>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}