/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config"

/**
 * Clients / Leads / Targets
 */


const getCampaigns = async () => {

    const options = {
        base: "https://marketing-middleware",
        params: false,
        payload: false,
        endpoint: `/api/campaign`,
        method: "GET"
    }


    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        },
    );

    const res = await data.json();

    return res;

}

export { getCampaigns }