import { Button } from "@mui/material";
import React from "react";
import { BallTriangle } from "react-loader-spinner";

/**
 *
 * @param {*} props
 *  - timedOut | Boolean | Renders Error Message
 *  - triggerTimeout | Function | Sets Timedout
 *  - color | string | sets the color of the loader
 *  - length | integer | Sets duration in ms to when timeout is triggered | default 30000
 * @returns
 */
export default function TimeoutLoader(props) {
  setTimeout(
    () => {
      props.triggerTimeout();
    },
    props.length ? props.length : 30000
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
        marginTop: "5%",
      }}
    >
      <BallTriangle
        color={props.color ? props.color : "black"}
        height={80}
        width={80}
      />
      {props.timedOut && (
        <p>
          An Error Has Occured!
          <br />
          <button
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </p>
      )}
    </div>
  );
}
