/**
 * @description     \ Materials service express routes
 * @baseURL         \ https://materials-service
 */
import config from "../config"

/**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
const getCategories = async () => {
  const options = {
    base: "https://materials-service",
    params: false,
    payload: false,
    endpoint: `/api/categories`,
    method: "GET"
  }
  
  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  
  const res = await data.json();
  
  return res;
};

/**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
const getCategoryMaterials = async (id) => {
  const options = {
    base: "https://materials-service",
    params: false,
    payload: false,
    endpoint: `/api/materials/categoryIndex/${id}`,
    method: "GET"
  }
  
  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  
  const res = await data.json();
  
  return res;
};

export {
    getCategories,
    getCategoryMaterials
};