/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

const getLeadsIndex = async () => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/leads",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsIndexOpen = async () => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/lead/indexByOpen",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsIndexByClosed = async () => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/lead/indexByClosedOrLost",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsIndexByConverted = async () => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/lead/indexByConverted",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsRestoreIndex = async () => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/lead/restoreIndex",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const showLeads = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/leads/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createLeads = async (obj) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/leads`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateLeads = async (id, obj) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/leads/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteLeads = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/leads/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreLeads = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/lead/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const markLeadLost = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/lead/${id}/setLost`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const markLeadClosed = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/lead/${id}/setClosed`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const markLeadWon = async (id) => {
  const options = {
    base: "https://leads-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/lead/${id}/setConverted`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getFiles = async (id) => {
  const options = {
    base: "https://document-vault-service",
    params: false,
    payload: false,
    endpoint: `/api/document/${id}/downloadLink`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getLeadsIndex,
  getLeadsIndexByClosed,
  getLeadsIndexByConverted,
  getLeadsIndexOpen,
  getLeadsRestoreIndex,
  updateLeads,
  createLeads,
  deleteLeads,
  restoreLeads,
  showLeads,
  markLeadClosed,
  markLeadWon,
  markLeadLost,
  getFiles,
};
