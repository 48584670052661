import config from "../config";

const documentsIndex = async (all = 0) => {
    const options = {
        base: "https://document-vault-service",
        params: false,
        payload: false,
        endpoint: `/api/document?public=${all}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    const res = await data.json();

    return res;
};

const documentsCustomerIndex = async (customer_id) => {
    const options = {
        base: "https://document-vault-service",
        params: false,
        payload: false,
        endpoint: `/api/document/indexByCustomer/${customer_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const documentsApplicationIndex = async (app_id) => {
    const options = {
        base: "https://document-vault-service",
        params: false,
        payload: false,
        endpoint: `/api/document/indexByApplication?application_id=${app_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    const res = await data.json();

    return res;
};

const storeDocument = async (obj) => {
    const options = {
      base: "https://document-vault-service",
      params: false,
      payload: obj,
      endpoint: "/api/document",
      method: "POST",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

// Will generate a download link *** Only valid for 5 minutes
const generateTempLink = async (doc_id) => {
    const options = {
        base: "https://document-vault-service",
        params: false,
        payload: false,
        endpoint: `/api/document/${doc_id}/downloadLink`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    const res = await data.json();

    return res;
}

// Will generate a fixed link, only generate when absouletely required
const generateFixedLink = async (doc_id) => {
    const options = {
        base: "https://document-vault-service",
        params: false,
        payload: false,
        endpoint: `/api/document/${doc_id}/fixedDownloadLink`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    const res = await data.json();

    return res;
}

export {
    documentsIndex,
    documentsCustomerIndex,
    documentsApplicationIndex,
    storeDocument,
    generateTempLink,
    generateFixedLink,
};