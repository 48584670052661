import config from "../config";

const getActivityIndex = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/${id}/index`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getActivityRecord = async (id, activityid) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/${activityid}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateActivity = async (id, activityid, obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticket/${id}/activity/${activityid}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createActivity = async (id, obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticket/${id}/activity`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export { getActivityIndex, getActivityRecord, updateActivity, createActivity };
