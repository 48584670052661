import React from "react";
import { Puff } from "react-loader-spinner";

export default function SmallLoading({ color = "black" }) {
  return (
    <div>
      <Puff
        color={color}
        height={30}
        width={30}
      />
    </div>
  );
}
