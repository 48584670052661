/**
 * @description     \ Document Creator middleware express routes
 * @baseURL         \ https://doc-creator-micro-middleware
 */
 import config from "../config";


 /**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
 const getTypes = async () => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/types`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getDocument = async (id) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/documents/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getThemeLogs = async (id) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/logs/themeIndex/${id}`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getVersionLogs = async (id, version) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/logs/versionIndex/${id}/${version}`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveDocument = async (obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const saveSection = async (obj) => {
    const options = {
      base: "https://doc-creator-micro-middleware",
      params: false,
      payload: obj,
      endpoint: `/api/sections`,
      method: "POST"
    }
  
    const data = await fetch(
      `${config.appURL}/api/proxy`,
      {
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  
    const res = await data.json();
  
    return res;
  };


  const saveLog = async (obj) => {
     const options = {
       base: "https://doc-creator-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/logs`,
       method: "POST"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };

 
   const getActionDocuments = async (obj) => {
     const options = {
       base: "https://doc-creator-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/documents/actionIndex`,
       method: "POST"
     }
    
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
    
     const res = await data.json();
    
     return res;
   };
  
   
   const getPublishedDocuments = async (obj) => {
     const options = {
       base: "https://doc-creator-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/documents/publishedIndex`,
       method: "POST"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };
  
   
   const getRetiredDocuments = async (obj) => {
     const options = {
       base: "https://doc-creator-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/documents/retiredIndex`,
       method: "POST"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };
  
   
   const getWorkingDocuments = async (obj) => {
     const options = {
       base: "https://doc-creator-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/documents/workingIndex`,
       method: "POST"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const updateDocument = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const documentApproval = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/approve/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const documentCancellation = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/cancel/${id}`,
     method: "PUT"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };


 const documentPublishing = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/publish/${id}`,
     method: "PUT"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };


 const documentRejection = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/reject/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const documentRetirement = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/retire/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const documentRequest = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/requestApproval/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const updateSection = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/sections/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const unlinkSection = async (id, obj) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/sections/unlink/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteDocument = async (id) => {
   const options = {
     base: "https://doc-creator-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/documents/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 
 
 
 export {
   getTypes,
   getDocument,
   getThemeLogs,
   getVersionLogs,
   saveDocument,
   saveSection,
   saveLog,
   getActionDocuments,
   getPublishedDocuments,
   getRetiredDocuments,
   getWorkingDocuments,
   updateDocument,
   documentApproval,
   documentCancellation,
   documentPublishing,
   documentRejection,
   documentRetirement,
   documentRequest,
   updateSection,
   unlinkSection,
   deleteDocument
 };
 