/**
 * Acts as a Wrapper for Javascript Date Class
 * 
 * Pass string date and class will attempt to convert that to a JS date.
 * If the parse fails false will be returned.
 */
class DateHandler {
    constructor(date) {
        try {
            this.date = new Date( date );

            if( this.date == "Invalid Date" ) {
                this.date = false;
            }
        } catch (error) {
            this.date = false;
        }
    }

    // Convert to browser local time
    UTCToLocalVerboseString() {
        if(!this.date) return "Corrupt Date";

        // Empty Array Forces Local String to Use Browsers Default Locale
        return this.date.toLocaleString(
            [], {
                dateStyle: "medium",
                timeStyle: "medium"
            }
        );
    }

    // Convert to browser local time (shortened)
    UTCToLocalShortString() {
        if(!this.date) return "Corrupt Date";

        return this.date.toLocaleString(
            [], {
                dateStyle: "short",
                timeStyle: "short"
            }
        );
    }

    // Convert to browser local date
    UTCToLocalDateString() {
        if(!this.date) return "Corrupt Date";

        return this.date.toLocaleString(
            [], {
                dateStyle: "short",
            }
        );
    }

    // Convert to browser time only
    UTCToLocalTimeString() {
        if(!this.date) return "Corrupt Date";
        
        return this.date.toLocaleString(
            [], {
                timeStyle: "short",
            }
        );
    }
}

export default DateHandler;