/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

const getCodes = async () => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/codes`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const typesIndex = async () => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/types`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createType = async (obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: "/api/types",
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const showType = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/types/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateType = async (id, obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: `/api/types/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteType = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/types/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const optionsIndexForType = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForType/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createOption = async (obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: `/api/options`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const showOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateOption = async (id, obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: `/api/options/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/restore/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const subOptionsIndexForType = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/subOptions/indexForType/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const subOptionsIndexForOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/subOptions/indexForOption/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createSubOption = async (obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: `/api/subOptions`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const showSubOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/subOptions/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateSubOption = async (id, obj) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: `/api/subOptions/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteSubOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/subOptions/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreSubOption = async (id) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/subOptions/restore/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getChargeCodes = async () => {
  //const_alias 1 is the identifier for charge codes
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/1`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPaymentMethods = async () => {
  //const_alias 2 is the identifier for payment methods
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/2`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPeriodicCodes = async () => {
  //const_alias 3 is the identifier for periodic codes
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/3`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getBusinessUnits = async () => {
  //const_alias 4 is business units
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/4`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getMeasurementUnits = async () => {
  //const_alias 5 is measurement units
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/5`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getVatRates = async () => {
  //const_alias 6 is vat rates
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/6`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getNotifications = async () => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: "/api/notifications",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveNotifications = async ( obj ) => {
  const options = {
    base: "https://codes-service",
    params: false,
    payload: obj,
    endpoint: "/api/notifications",
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketStates = async () => {
  //const_alias 8 is ticket states
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/8`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSalesChannels = async () => {
  //const_alias 9 is ticket states
  const options = {
    base: "https://codes-service",
    params: false,
    payload: false,
    endpoint: `/api/options/indexForAlias/9`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getCodes,
  typesIndex,
  createType,
  showType,
  updateType,
  deleteType,
  optionsIndexForType,
  createOption,
  showOption,
  updateOption,
  deleteOption,
  restoreOption,
  subOptionsIndexForType,
  subOptionsIndexForOption,
  createSubOption,
  showSubOption,
  updateSubOption,
  deleteSubOption,
  restoreSubOption,
  getChargeCodes,
  getPaymentMethods,
  getPeriodicCodes,
  getBusinessUnits,
  getMeasurementUnits,
  getVatRates,
  getNotifications,
  saveNotifications,
  getTicketStates,
  getSalesChannels
};
