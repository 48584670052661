import config from "../config";

const activitiesIndex = async (type, customer_id, filter_id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/${type}/${customer_id}/${filter_id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveActivity = async (type, obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/activity/${type}`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateActivity = async (type, obj, id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/activity/${type}/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getActivitySingle = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCallActions = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/callAction`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveCallAction = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/callAction`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteCallAction = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/callAction/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getListIndex = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/list`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTemplatesIndex = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/templates`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTemplate = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/templates`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTemplateSingle = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/templates/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateTemplate = async (obj, id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/templates/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCallScripts = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/callScript`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCallScriptSingle = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/callScript/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveCallScript = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/callScript`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateCallScript = async (obj, id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/callScript/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteCallScript = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/callScript/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreCallScript = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/callScript/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteBulkEmail = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/bulkEmail/${id}/cancelBatch`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getBatchStatus = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/bulkEmail/${id}/batchStatus`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveLog = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/log/action`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getActivityByAssociation = async (id, type) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/indexByAssociation/${id}?type=${type}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getActivitysByDeadline = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/activity/indexByDeadline`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const sendEmail = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/email/${id}/send`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getUnsubGroups = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/sendgrid/unsubscribeGroups`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getUnsubGroupsEmails = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/sendgrid/${id}/unsubscribeGroupEmails`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getEmailTags = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/emailTag`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const saveEmailTag = async (obj) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/emailTag`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const deleteEmailTag = async (tag_id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/emailTag/${tag_id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

export {
  activitiesIndex,
  saveActivity,
  updateActivity,
  getActivitySingle,
  getCallActions,
  saveCallAction,
  deleteCallAction,
  getListIndex,
  getTemplatesIndex,
  saveTemplate,
  getTemplateSingle,
  updateTemplate,
  getCallScripts,
  getCallScriptSingle,
  saveCallScript,
  updateCallScript,
  deleteCallScript,
  restoreCallScript,
  deleteBulkEmail,
  getBatchStatus,
  saveLog,
  getActivityByAssociation,
  getActivitysByDeadline,
  sendEmail,
  getUnsubGroups,
  getUnsubGroupsEmails,
  getEmailTags,
  saveEmailTag,
  deleteEmailTag
};
