import config from "../config";

const getTargets = async (search, page) => {
  typeof search === "undefined" || typeof null !== search
    ? search
    : (search = "");

  const options = {
    base: "https://marketing-middleware",
    params: search,
    page,
    payload: false,
    endpoint: `/api/targets?search=${search}&page=${page}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getRestoreTargets = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: "/api/targets/restoreIndex",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSingleTarget = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: id,
    payload: false,
    endpoint: `/api/targets/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTarget = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: "/api/targets",
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateTarget = async (obj, id) => {
  const options = {
    base: "https://marketing-middleware",
    params: id,
    payload: obj,
    endpoint: `/api/targets/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteTarget = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreTarget = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetCountTargets = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetCount`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetPaginateTargets = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetPaginate`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetSearchTargets = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetSearch`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const filterSetNotSearch = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetNotSearch`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCriteriaFuzzySearch = async (endpoint, fieldName, obj) => {
  const newObj = {
    search: obj,
  };
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: newObj,
    endpoint: `/api/${endpoint}/${fieldName}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const importerStore = async (obj) => {
  const newObj = {
    targets: obj,
  };

  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: newObj,
    endpoint: `/api/targets/storeMany`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const unsubscribe = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/targets/${id}/unsubscribe`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const setTagType = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targets/filterSetTagSet`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getTargets,
  getRestoreTargets,
  getSingleTarget,
  saveTarget,
  updateTarget,
  deleteTarget,
  restoreTarget,
  filterSetCountTargets,
  filterSetPaginateTargets,
  filterSetSearchTargets,
  filterSetNotSearch,
  getCriteriaFuzzySearch,
  importerStore,
  unsubscribe,
  setTagType,
};
