import config from "../config";

/**
 * =============================
 *          JOB ROUTES
 * =============================
 */

const jobIndex = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/job`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobIndexWithUserSettings = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/job/withUserSettings`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobShow = async (job_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/job/${job_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/job`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/job/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobDelete = async (job_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/job/${job_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobRestore = async (job_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/job/${job_id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *      JOB INSTANCE ROUTES
 * =============================
 */

const jobInstanceIndex = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobinstance`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceIndexForJob = async (job_id, page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobinstance/job/${job_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceIndexForWorker = async (worker_id, closed = false, page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage,
            closed: closed
        },
        payload: false,
        endpoint: `/api/jobinstance/worker/${worker_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceShow = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobinstance/${instance_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobinstance`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobinstance/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceDelete = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobinstance/${instance_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobInstanceRestore = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobinstance/${instance_id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *    JOB USER SETTINGS ROUTES
 * =============================
 */

const jobUserSettingsIndex = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobusersettings`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUserSettingsIndexForJob = async (job_id, page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobUserSettings/job/${job_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUserSettingsShow = async (settings_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobusersettings/${settings_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUserSettingsStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobusersettings`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUserSettingsUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobusersettings/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobUserSettingsDelete = async (settings_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobusersettings/${settings_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *       JOB WORKER ROUTES
 * =============================
 */

const jobWorkerIndex = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobworker`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerIndexForJob = async (job_id, page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/jobworker/job/${job_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerShow = async (worker_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobworker/${worker_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobworker`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/jobworker/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerDelete = async (worker_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobworker/${worker_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const jobWorkerRestore = async (worker_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/jobworker/${worker_id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *  ACTION STATUS TYPES ROUTES
 * =============================
 */

const actionStatusTypeIndex = async (page = 1, perPage = 100) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/actionstatustype`,
        method: "GET"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const actionStatusTypeShow = async (status_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/actionstatustype/${status_id}`,
        method: "GET"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const actionStatusTypeStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/actionstatustype`,
        method: "POST"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const actionStatusTypeUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/actionstatustype/${payload.id}`,
        method: "PUT"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const actionStatusTypeDelete = async (status_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/actionstatustype/${status_id}`,
        method: "DELETE"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const actionStatusTypeRestore = async (status_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/actionstatustype/${status_id}/restore`,
        method: "GET"
    }

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}


/**
 * =============================
 *     TASK INSTANCE ROUTES
 * =============================
 */

const taskInstanceIndex = async (page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/taskinstance`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceIndexForTask = async (task_id, page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/taskInstance/task/${task_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceIndexForJobInstance = async (instance_id, page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/taskinstance/jobinstance/${instance_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceIndexForKanbanBoard = async (board_id, page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/taskinstance/kanbanboard/${board_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceShow = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/taskinstance/${instance_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/taskinstance`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/taskinstance/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceDelete = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/taskinstance/${instance_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const taskInstanceRestore = async (instance_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/taskinstance/${instance_id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}


/**
 * =============================
 *     KANBAN BOARD ROUTES
 * =============================
 */
const kanbanIndex = async (page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/kanbanboard`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanIndexForTeam = async (team_id, page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/kanbanboard/team/${team_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanShow = async (board_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/kanbanboard/${board_id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/kanbanboard`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/kanbanboard/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanDelete = async (board_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/kanbanboard/${board_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanRestore = async (board_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/kanbanboard/${board_id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanAddTeam = async (board_id, team_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: [],
        endpoint: `/api/kanbanboard/${board_id}/team/${team_id}`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const kanbanRemoveTeam = async (board_id, team_id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: [],
        endpoint: `/api/kanbanboard/${board_id}/team/${team_id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *          TEAM ROUTES
 * =============================
 */
const teamIndex = async (page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/team`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamShow = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/team/${id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/team`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/team/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamDelete = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/team/${id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamRestore = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/team/${id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

/**
 * =============================
 *       TEAM MEMBER ROUTES
 * =============================
 */
const teamMemberIndex = async (page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/teammember`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberIndexForTeam = async (id, page = 1, perPage = 30) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: {
            page: page,
            perPage: perPage
        },
        payload: false,
        endpoint: `/api/teammember/team/${id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberShow = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/teammember/${id}`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberStore = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/teammember`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberUpdate = async (payload) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/teammember/${payload.id}`,
        method: "PUT"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberDelete = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/teammember/${id}`,
        method: "DELETE"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

const teamMemberRestore = async (id) => {
    const options = {
        base: "https://fulfilment-middleware",
        params: false,
        payload: false,
        endpoint: `/api/teammember/${id}/restore`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    const res = await data.json();
    res.success = data.ok;

    return res;
}

export {
    jobIndex,
    jobIndexWithUserSettings,
    jobShow,
    jobStore,
    jobUpdate,
    jobDelete,
    jobRestore,

    jobInstanceIndex,
    jobInstanceIndexForJob,
    jobInstanceIndexForWorker,
    jobInstanceShow,
    jobInstanceStore,
    jobInstanceUpdate,
    jobInstanceDelete,
    jobInstanceRestore,

    jobWorkerIndex,
    jobWorkerIndexForJob,
    jobWorkerShow,
    jobWorkerStore,
    jobWorkerUpdate,
    jobWorkerDelete,
    jobWorkerRestore,

    jobUserSettingsIndex,
    jobUserSettingsIndexForJob,
    jobUserSettingsShow,
    jobUserSettingsStore,
    jobUserSettingsUpdate,
    jobUserSettingsDelete,

    actionStatusTypeIndex,
    actionStatusTypeShow,
    actionStatusTypeStore,
    actionStatusTypeUpdate,
    actionStatusTypeDelete,
    actionStatusTypeRestore,

    taskInstanceIndex,
    taskInstanceIndexForTask,
    taskInstanceIndexForJobInstance,
    taskInstanceIndexForKanbanBoard,
    taskInstanceShow,
    taskInstanceStore,
    taskInstanceUpdate,
    taskInstanceDelete,
    taskInstanceRestore,

    kanbanIndex,
    kanbanIndexForTeam,
    kanbanShow,
    kanbanStore,
    kanbanUpdate,
    kanbanDelete,
    kanbanRestore,
    kanbanAddTeam,
    kanbanRemoveTeam,

    teamIndex,
    teamShow,
    teamStore,
    teamUpdate,
    teamDelete,
    teamRestore,

    teamMemberIndex,
    teamMemberIndexForTeam,
    teamMemberShow,
    teamMemberStore,
    teamMemberUpdate,
    teamMemberDelete,
    teamMemberRestore
}