import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Button } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import React, { useState, useEffect } from "react";

/**
 * Wrapper for MUI/Material Dialog Box. Will optionally write a button to page and will create a dialog
 * 
 * @params props {}
 * - props.openBtn | Optional | Object | If undefined no dialog open button will be displayed
 * - props.openBtn.text | Optional | String | Determines text of open dialog button. If null icon button will be displayed instead
 * - props.openBtn.color | Optional | String(HTMLColor) | Determine background color of button. (Only works if text is provided)
 * - props.open | Optional | Boolean | Will override the components interal open flag. Use to force dialog to open or close
 * - props.title | Optional | String | Header display on modal
 * - props.body | Optional | String or JSX Element | String or element to display in the main body of the dialog
 * - props.action1 | Optional | Object | Determines text and handler for the first button at the bottom of the modal. Won't render if undef
 * - props.action1.handle | Optional | Function | onClick Handler for action1 button
 * - props.action1.text | Optional | String | Text to display for action1 button
 * - props.action2 | Optional | Object | Determines text and handler for the first button at the bottom of the modal. Won't render if undef
 * - props.action2.handle | Optional | Function | onClick Handler for action2 button
 * - props.action2.text | Optional | String | Text to display for action2 button
 * 
 * Example:
 *  props = {
 *      openBtn: {
 *          text: "Open Dialog"
 *          color: "Black"
 *      },
 *      open: false,
 *      title: "Hello World!",
 *      body: (<p>This is a dialog!</p>),
 *      action1: {
 *          handle: () => {console.log("Hi There!")},
 *          text: "Hey"
 *      },
 *      action2: {
 *          handle: () => {console.log("Bye Then")},
 *          text: "Bye"
 *      }
 * }
 */
export default function BasicDialog(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(props.open !== undefined ? props.open : false);
    }, [props.open]);

    return (
        <>
        <div>
            {
                props.openBtn !== undefined && (
                    <>
                        {
                            props.openBtn.text !== undefined ? ( 
                                <Button 
                                    variant="outlined" 
                                    style={{ backgroundColor: props.openBtn.color ? props.openBtn.color : "Blue" }}
                                    onClick={() => {handleOpen()}}
                                >
                                    { props.openBtn.text }
                                </Button>
                            ) : (
                                <IconButton onClick={() => {handleOpen()}}>
                                    <OpenInFullIcon />
                                </IconButton>
                            )
                        }
                    </>
                )
            }
        </div>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        props.action1 !== undefined && (
                            <Button onClick={props.action1.handle}>{props.action1.text}</Button>
                        )
                    }
                    {
                        props.action2 !== undefined && (
                            <Button onClick={props.action2.handle}>{props.action2.text}</Button>
                        )
                    }
                </DialogActions>
            </Dialog>

        </>
    )
}