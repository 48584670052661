import config from "../config";

/**
 * ========================
 *    Company Meta Info
 * ========================
 */

const socIndex = async (page, search = '', perPage = 100) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "SOC Codes"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const identificationTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "identification_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const jurisdictionIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "jurisdiction"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companySummaryIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_summary"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companyTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companySubTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_subtype"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companyBirthTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_birth_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const proofStatusIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "proof_status"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companyStatusIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_status"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const companyStatusDetailIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "company_status_detail"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const foreignAccountTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "foreign_account_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const termsOfAccountPublicationIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "terms_of_account_publication"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const accountTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "account_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const officerRoleIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "officer_role"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const filingTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "filing_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const filingStatusIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "filing_status"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const insolvencyCaseTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "insolvency_case_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const insolvencyCaseDateTypeIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "insolvency_case_date_type"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const sicDescriptionsIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "sic_descriptions"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const notesIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "notes"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const cessationLabelForStatusIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "cessation_label_for_status"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const registerLocationsIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "register_locations"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const registerTypesIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "register_types"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

const partialDataAvailableIndex = async (page, search = '', perPage = 1) => {
    const options = {
        base: "https://entity-service",
        params: {
            page: page, 
            search: search, 
            perPage: perPage,
            type_name: "partial_data_available"
        },
        payload: false,
        endpoint: `/api/entity/indexByType`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
      res.success = res.ok
    
      return res;
}

export {
    socIndex,
    identificationTypeIndex,
    jurisdictionIndex,
    companySummaryIndex,
    companyTypeIndex,
    companySubTypeIndex,
    proofStatusIndex,
    companyStatusIndex,
    companyBirthTypeIndex,
    companyStatusDetailIndex,
    foreignAccountTypeIndex,
    termsOfAccountPublicationIndex,
    accountTypeIndex,
    officerRoleIndex,
    filingTypeIndex,
    filingStatusIndex,
    insolvencyCaseTypeIndex,
    insolvencyCaseDateTypeIndex,
    sicDescriptionsIndex,
    notesIndex,
    cessationLabelForStatusIndex,
    registerLocationsIndex,
    registerTypesIndex,
    partialDataAvailableIndex,

}