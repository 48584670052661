import config from "../config";

const sendMessage = async (obj) => {
    const options = {
      base: "https://entity-service",
      params: false,
      payload: obj,
      endpoint: `/api/entity`,
      method: "POST"
    }
  
    const data = await fetch(
      `${config.appURL}/api/proxy`,
      {
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  
    const res = await data.json();
  
    return res;
  };

  export { sendMessage };