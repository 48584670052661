import config from "../config";

const getActivities = async () => {
    const options = {
        base: "https://contacts-service.rrdevelopment.co.uk",
        params: false,
        payload: false,
        endpoint: "/api/activities",
        method: "GET",
        service: true

    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};


const getUserActivities = async (id) => {
    const options = {
        base: "https://contacts-service.rrdevelopment.co.uk",
        params: false,
        payload: false,
        endpoint: `/api/activities/indexForUser/${id}`,
        method: "GET",
        service: true

    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};


const getCustomerLogs = async (id) => {
    const options = {
        base: "https://contacts-service.rrdevelopment.co.uk",
        params: false,
        payload: false,
        endpoint: `/api/activities/customerIndex/${id}`,
        method: "GET",
        service: true

    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};


const getActivity = async (id) => {
    const options = {
        base: "https://contacts-service.rrdevelopment.co.uk",
        params: false,
        payload: false,
        endpoint: `/api/activities/${id}`,
        method: "GET",
        service: true


    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

export { getActivities, getActivity, getUserActivities, getCustomerLogs }