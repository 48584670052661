import config from "../config";


  const getApplication = async (id) => {
    console.log("getApplication ID " , id)

    const options = {
      params: { id : id },
      payload: false,
      endpoint: `/api/application`,
      method: "GET",
    };
  
    const data = await fetch(`${config.appURL}/api/fusionAuth`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

  export { getApplication }