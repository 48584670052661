import config from "../config";

const getCampaign = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: "/api/campaign",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignUsersIndex = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: "/api/campaign/userIndex",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignSingle = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/campaign/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignWithRelations = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/campaign/${id}/showWithRelations`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveCampaign = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: "/api/campaign",
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateCampaign = async (id, obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/campaign/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignCreateSetup = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: "/api/campaignCreateSetup",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteCampaign = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/campaignCreateSetup/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignLink = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/campaign/${id}/getLink`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveResponse = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/response`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponsesIndexByCampaign = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/response/indexByCampaign/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeads = async () => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/leads`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const saveLead = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/leads`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponseByParent = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/response/indexByParent/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponseSingle = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/response/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignStats = async (id) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/sendgrid/campaignStats/${id}?aggregated_by=month`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getFullStats = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/sendgrid/fullStats?aggregated_by=month`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getUserStats = async () => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/sendgrid/userStats?aggregated_by=month`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponseCount = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/ds13`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLegacyPeopleCount = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/ds11`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCompletedActivities = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/ds12`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCampaignEmailOneOnOneReport = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/reports/campaign/${id}/email`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsInCampaign = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/ds14`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLeadsByCampaign = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/leads/indexByCampaign/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDocumentLink = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/campaign/${id}/getLink`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteResponse = async (id) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/response/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getMarketingLeads = async (uuid, dateFrom, dateTo) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/response/indexByUser?user_uuid=${uuid}&date_from=${dateFrom}&date_to=${dateTo}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getSalesLeads = async (uuid, dateFrom, dateTo) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: false,
    endpoint: `/api/leads/indexByUser?created_by=${uuid}&date_from=${dateFrom}&date_to=${dateTo}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getNumberofTouches = async (id, dateFrom, dateTo) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/indexByUser?agent_id=${id}&date_from=${dateFrom}&date_to=${dateTo}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getNumberofTouchesVerbose = async (id, dateFrom, dateTo) => {
  const options = {
    base: "https://activity-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/activity/userReport?agent_id=${id}&date_from=${dateFrom}&date_to=${dateTo}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getCampaign,
  getCampaignUsersIndex,
  getCampaignSingle,
  getCampaignWithRelations,
  saveCampaign,
  updateCampaign,
  getCampaignCreateSetup,
  deleteCampaign,
  getCampaignLink,
  saveResponse,
  getResponsesIndexByCampaign,
  getLeads,
  saveLead,
  getResponseByParent,
  getResponseSingle,
  getCampaignStats,
  getFullStats,
  getResponseCount,
  getCompletedActivities,
  getCampaignEmailOneOnOneReport,
  getLeadsInCampaign,
  getUserStats,
  getLegacyPeopleCount,
  getLeadsByCampaign,
  getDocumentLink,
  deleteResponse,
  getMarketingLeads,
  getSalesLeads,
  getNumberofTouches,
  getNumberofTouchesVerbose
};
