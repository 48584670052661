/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

import config from "../config"


const getRoles = async () => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/getRoles`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const saveRoles = async (obj) => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/settings/saveRoles`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */



const deleteRoles = async (id) => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/deleteRoles/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */



const getPermissions = async (id) => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/settings/getPermissions/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



const savePermissions = async (obj) => {
  const options = {
    base: "https://settings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/settings/savePermissions`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};





export {
  getRoles,
  saveRoles,
  deleteRoles,
  getPermissions,
  savePermissions,
};
