/**
 * @description     \ Finance service express routes
 * @baseURL         \ https://finance-service
 */
import config from "../config"

/**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
const getPositions = async () => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: false,
    endpoint: `/api/position`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
};

const getPositionForUser = async (user_id) => {
  const options = {
    base: "https://finance-service",
    params: { user_id: user_id },
    payload: false,
    endpoint: `/api/position/indexForUser`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const getPositionsRestoreIndex = async () => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: false,
    endpoint: `/api/position/restoreIndex`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const getPosition = async (position_id) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: false,
    endpoint: `/api/position/${position_id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const savePosition = async (payload = {}) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: payload,
    endpoint: `/api/position`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const updatePosition = async (position_id, payload) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: payload,
    endpoint: `/api/position/${position_id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const deletePosition = async (position_id) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: false,
    endpoint: `/api/position/${position_id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const restorePosition = async (position_id) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: false,
    endpoint: `/api/position/restore/${position_id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const setPosition = async (payload) => {
  const options = {
    base: "https://finance-service",
    params: false,
    payload: payload,
    endpoint: `/api/position/set`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const unsetPosition = async (position_id, user_id) => {
  const options = {
    base: "https://finance-service",
    params: { position_id: position_id, user_id: user_id },
    payload: false,
    endpoint: `/api/position/unset`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

export {
  getPositions,
  getPositionForUser,
  getPositionsRestoreIndex,
  getPosition,
  savePosition,
  updatePosition,
  deletePosition,
  restorePosition,
  setPosition,
  unsetPosition
};