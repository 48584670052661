import React from "react";
import { BallTriangle } from "react-loader-spinner";

export default function Loading({ color = false }) {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", height: "100%", marginTop: "5%" }}>
      <BallTriangle
        color={color}
        height={80}
        width={80}
      />
    </div>
  );
}
