import config from "../config";

const saveClients = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/clientStore`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTargets = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/targetStore`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const storeSite = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/siteStore`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const storeContact = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contactStore`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  console.log(data);

  const res = await data.json();

  return res;
};

export { saveClients, saveTargets, storeSite, storeContact };
