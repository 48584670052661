import React, { useEffect } from "react";

/*
    inputs:
        props.name - Name of the application
        props.number - integer
        props.message - string
        props.icon - JSX component
*/
export default function VersionPusher(props) {
    const dispatchEvent = () => {
        setTimeout(() => {
            let versionArray = window.sessionStorage.getItem('versions');
            if(versionArray == undefined) versionArray = []; else {
                try {
                    versionArray = JSON.parse(versionArray)
                } catch (error) { versionArray = [] }
            };

            let names = versionArray.map((v) => {return v.name});
            let check = versionArray.filter((v) => names.indexOf(v.name) != -1);
            if(check.length > 0) return;

            versionArray.push({
                name: props.name,
                number: props.number,
                message: props.message,
            });
            window.sessionStorage.setItem("versions", JSON.stringify(versionArray));

            window.dispatchEvent(new CustomEvent(
                `version-update`, 
                {
                    bubbles: true,
                    cancelable: true,
                    detail: true
                }
            ));
        }, 5000);
    }



    useEffect(() => {
        const grant = window.localStorage.getItem("grant");
        try {
            if(grant == undefined || !parseInt(grant)) return;    
        } catch(error) {return;}
        
        dispatchEvent();
        window.addEventListener(`version-request`, (e) => {
            dispatchEvent();
        });
    }, []);

    return (<></>)
}