import config from "../config";
const DEFAULTPERPAPGE = 30;

const templateIndex = async (page, perPage) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: {page: page, perPage: perPage},
        payload: false,
        endpoint: `/api/exportSDK`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateShow = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/exportSDK/${templateId}`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateStore = async (payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/exportSDK`,
        method: "POST",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateUpdate = async (templateId, payload) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/exportSDK/${templateId}`,
        method: "PUT",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateDestroy = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/exportSDK/${templateId}`,
        method: "DELETE",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

const templateRestore = async (templateId) => {
    const options = {
        base: "https://entity-forms-middleware",
        params: false,
        payload: false,
        endpoint: `/api/exportSDK/${templateId}/restore`,
        method: "GET",
      };
    
      const data = await fetch(`${config.appURL}/api/proxy`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
      res.ok = data.status == 200;
    
      return res;
}

export {
    templateIndex,
    templateShow,
    templateStore,
    templateUpdate,
    templateDestroy,
    templateRestore
}