import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */

const getSuppliers = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/suppliers`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getApprovedSuppliers = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/suppliers/approvedIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getPendingSuppliers = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/suppliers/pendingIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getUnapprovedSuppliers = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/suppliers/unapprovedIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};


const saveSuppliers = async (obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/suppliers`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getSupplierSingle = async (id) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/suppliers/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};


const updateSupplier = async (obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/suppliers/${obj.id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const approveSupplier = async (id, obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/suppliers/approve/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const rejectSupplier = async (id, obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/suppliers/unapprove/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

// Products

const createProduct = async (obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/products`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const updateProduct = async (id, obj) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/products/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getProductSingle = async (id) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getProducts = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getProductsForSupplier = async (supplier_id) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/supplierIndex/${supplier_id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const deleteProduct = async (id) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/${id}`,
        method: "DELETE"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getProductsRestoreIndex = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/restoreIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const restoreProduct = async (id) => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/restore/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getSupplierTypes = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/types`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

const getProductCategories = async () => {
    const options = {
        base: "https://suppliers-micro-middleware",
        params: false,
        payload: false,
        endpoint: `/api/products/categories`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();

    return res;
};

export {
    getApprovedSuppliers,
    saveSuppliers,
    getPendingSuppliers,
    getUnapprovedSuppliers,
    getSupplierSingle,
    updateSupplier,
    getSuppliers,
    approveSupplier,
    rejectSupplier,
    createProduct,
    updateProduct,
    getProductSingle,
    getProductsForSupplier,
    deleteProduct,
    getProductsRestoreIndex,
    restoreProduct,
    getSupplierTypes,
    getProductCategories
}