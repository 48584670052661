import config from "../config";

const saveLead = async (obj) => {
  const options = {
    base: "https://marketing-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/leads`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/////////////////////////////////////////////////
///////////////// Topics ////////////////////////
/////////////////////////////////////////////////
const getTopics = async () => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getOwnTopics = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/topic/indexForUser`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const saveTopic = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/topic`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTopicSingle = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const updateTopic = async (obj, id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/topic/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const deleteTopic = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: id,
    endpoint: `/api/topic/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const restoreTopic = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: id,
    endpoint: `/api/topic/${id}/restore`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getRestoreIndex = async () => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/restoreIndex`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const addTwitter = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/${id}/addTwitter`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const removeTwitter = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/${id}/removeTwitter`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const addLinkedin = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/${id}/addLinkedin`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const removeLinkedin = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/topic/${id}/removeLinkedin`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/////////////////////////////////////////////////
///////////////// Accounts  /////////////////////
/////////////////////////////////////////////////

const getAccounts = async (page = false, platform = false) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint:
      page === false && platform === false
        ? `/api/account`
        : `/api/account?page=${page}&platform=${platform}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getAccountSingle = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/account/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const saveAccount = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/account`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const updateAccount = async (obj, id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/account${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const accountDelete = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/account/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const accountRestore = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/account/${id}/restore`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const accountRestoreIndex = async (page = false) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/account/restoreIndex?page=${page}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/////////////////////////////////////////////////
/////////////////   Posts  //////////////////////
/////////////////////////////////////////////////

const getPostsForTopic = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/post/${id}/indexForTopic`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPostsForAccount = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/post/indexForManyAccounts`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

/////////////////////////////////////////////////
/////////////////  Twitter  /////////////////////
/////////////////////////////////////////////////

const twitterAuthAccount = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/twitter/authenticate`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const tweet = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/twitter/tweet`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const twitterReauth = async (id, obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/twitter/authenticate/${id}`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

// @Param Name:  lookup, retweetedBy, likedBy
const twitterTweetsLookup = async (id, name) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/twitter/tweet/1494303439454674949/${name}?account_id=${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteTweet = async (id, postId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/twitter/tweet/${postId}?account_id=${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const getTwitterUser = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/twitter/user/me?account_id=${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const twitterLookUp = async (id, postId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/twitter/tweet/${postId}/lookup?account_id=${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/////////////////////////////////////////////////
/////////////////  Linkedin  ////////////////////
/////////////////////////////////////////////////

const linkedinAuthAccount = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/linkedin/authenticate`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinReauth = async (id, obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/linkedin/authenticate/${id}`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinDeleteAccount = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/account/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinPost = async (obj) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/linkedin/share`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinOrgs = async (id) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/orgAccessInfo?account_id=${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinSocialActions = async (id, postId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/shareSocialActions?account_id=${id}&share_urn=${postId}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinPageStats = async (id, orgId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/orgPageStats?organization_urn=${orgId}&account_id=${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinDeleteShare = async (id, postId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/deleteShare/${postId}?account_id=${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const linkedinGetFollowers = async (id, orgId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/orgFollowers?account_id=${id}&organization_urn=${orgId}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const linkedinGetOrgShareDetails = async(id, orgId, shareId) => {
  const options = {
    base: "https://social-middleware",
    params: false,
    payload: false,
    endpoint: `/api/linkedin/orgShareStatistics?account_id=${id}&organization_urn=${orgId}&share_urn=${shareId}`,
    method: "PUT"
  }

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

export {
  saveLead,
  getTopics,
  saveTopic,
  getTopicSingle,
  updateTopic,
  deleteTopic,
  restoreTopic,
  getOwnTopics,
  addTwitter,
  removeTwitter,
  addLinkedin,
  removeLinkedin,
  getRestoreIndex,
  getAccounts,
  getAccountSingle,
  saveAccount,
  updateAccount,
  accountDelete,
  accountRestore,
  accountRestoreIndex,
  twitterAuthAccount,
  getPostsForTopic,
  getPostsForAccount,
  tweet,
  twitterReauth,
  twitterTweetsLookup,
  deleteTweet,
  getTwitterUser,
  twitterLookUp,
  linkedinAuthAccount,
  linkedinReauth,
  linkedinDeleteAccount,
  linkedinPost,
  linkedinOrgs,
  linkedinSocialActions,
  linkedinPageStats,
  linkedinDeleteShare,
  linkedinGetFollowers,
  linkedinGetOrgShareDetails
};
